<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="healthRifhts">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <el-input v-model="dataForm.cardId" placeholder="卡号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.activateCode" placeholder="激活码" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.personMobile" placeholder="激活手机号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.sendMobile" placeholder="发送手机号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.personName" placeholder="持卡人姓名" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.certificateNo" placeholder="持卡人身份证号" clearable></el-input>
        </el-form-item>

        <!-- <el-form-item>
          <el-select v-model="dataForm.status" :placeholder="$t('cardList.status')">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button @click="queryList()">{{ $t("query") }}</el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" @click="resetSearch()">{{
            $t('cardList.reset')
          }}</el-button>
        </el-form-item> -->
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" border @selection-change="dataListSelectionChangeHandle" @sort-change="dataListSortChangeHandle" style="width: 100%">
        <el-table-column prop="id" label="卡号" header-align="center" align="center"> </el-table-column>
        <el-table-column prop="packageName" label="权益卡名称" header-align="center" align="center"> </el-table-column>
        <el-table-column prop="activateDate" label="激活时间" header-align="center" align="center"> </el-table-column>
        <el-table-column prop="mobile" label="激活手机号" header-align="center" align="center"> </el-table-column>
        <el-table-column prop="sendMobile" label="发送手机号" header-align="center" align="center"> </el-table-column>
        <el-table-column prop="personName" label="持卡人姓名" header-align="center" align="center"> </el-table-column>
        <el-table-column prop="certificateNo" label="持卡人身份证号" header-align="center" align="center"> </el-table-column>
        <el-table-column prop="status" :label="$t('cardList.status')" header-align="center" align="center">
          <template slot-scope="scope">
            {{ statusHandler(scope.row.status) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="250">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="showDetailFuc(scope.row.id)">{{ $t("cardList.detail") }}</el-button>
            <el-button type="text" size="small" @click="resend(scope.row)">{{ "重发" }}</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
      <!-- <show-card-detail
        v-if="showDetail"
        ref="showCardDetail"
      ></show-card-detail> -->
    </div>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      dataList: [], // 数据列表
      order: "", // 排序，asc／desc
      orderField: "", // 排序，字段
      page: 1, // 当前页码
      limit: 10, // 每页数
      total: 0, // 总条数
      dataListLoading: false, // 数据列表，loading状态
      dataListSelections: [], // 数据列表，多选项
      addOrUpdateVisible: false, // 新增／更新，弹窗visible状态
      dataForm: {
        cardId: "", //卡号
        activateCode: "", // 激活码
        personMobile: "", // 激活手机号
        sendMobile: "", // 发送手机号
        personName: "", // 持卡人姓名
        certificateNo: "", // 持卡人身份证号
        page: 1,
        limit: 10,
        // status: "", // 状态 0：未激活 1：已激活
      },
      //  0：未激活 1：已激活
      options: [
        {
          value: "0",
          label: "未激活",
        },
        {
          value: "1",
          label: "已激活",
        },
        {
          value: "",
          label: "全部状态",
        },
      ],
    };
  },
  methods: {
    async resend(row) {
      let params = {
        id: row.id,
      };
      const { data: res } = await this.$http.get("/health/card/resend", {
        params,
      });
      const { data, code, msg } = res;
      if (code === 0) {
        this.$notify({
          title: "提示",
          message: "短信重发成功",
          type: "info",
          duration: 5000,
          type: "success",
        });
      } else {
        this.$notify.error({
          title: "提示",
          message: msg,
          type: "info",
          duration: 5000,
        });
      }
    },
    statusHandler(data) {
      const demo = this.options.filter((i) => i.value === String(data));
      return (demo.length && demo[0].label) || "";
    },

    // 查询
    queryList() {
      if (!this.dataForm.cardId && !this.dataForm.activateCode && !this.dataForm.personMobile && !this.dataForm.sendMobile && !this.dataForm.personName && !this.dataForm.certificateNo)
        return this.$message.error("至少要输入一个查询条件");
      let params = {
        ...this.dataForm,
      };
      this.$http
        .get("/health/card/onceQuery", {params:params})
        .then((res) => {
          console.log(res);
          if (res.data.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.dataList = res.data.data.list
          this.total = res.data.data.total
          console.log('this.dataList',this.dataList)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showDetailFuc (id){
      this.$router.push({
        path:'/cardListQueryDetail',
        query:{
          id:id
        }
      })
    },
    // 多选
    dataListSelectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 排序
    dataListSortChangeHandle(data) {
      if (!data.order || !data.prop) {
        this.order = "";
        this.orderField = "";
        return false;
      }
      this.order = data.order.replace(/ending$/, "");
      this.orderField = data.prop.replace(/([A-Z])/g, "_$1").toLowerCase();
      // this.query();
    },
    // 分页, 每页条数
    pageSizeChangeHandle(val) {
      this.dataForm.page = 1;
      this.dataForm.limit = val;
      this.queryList();
    },
    // 分页, 当前页
    pageCurrentChangeHandle(val) {
      this.dataForm.page = val;
      this.queryList();
    },
    getDataList() {
      this.dataForm.page = 1;
      this.queryList();
    },
  },
  components: {},
  created() {},
};
</script>
